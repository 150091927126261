import API from "../../core/api";
import { updateSite } from "../../core/api/services/siteService";
import { getBaseUrl } from "../../routes";
import { getThemeByProductCodePathFromSiteType } from "../../themes/constants";
import SiteCardDropdown from "./SiteCardDropdown";
import SiteCardImage from "./SiteCardImage";
import { css } from "@emotion/react";
import { Icon, theme, styleUtils, TextLink } from "@minted/minted-components";
import axios from "axios";
import config from "config";
import { addDays, differenceInDays, format as formatDate } from "date-fns";
import PropTypes from "prop-types";
import { React, useCallback, useEffect, useState } from "react";
import urljoin from "url-join";

const cellStyles = css`
  border: ${styleUtils.rem(1)} solid ${theme.colors.gray300};
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  grid-column: span 1;
`;

// This sets the size of our card containers to properly display the nested images
const weddingCellStyles = css`
  ${cellStyles};
  grid-column: span 2;
  width: 335px;
  height: 280px;

  ${theme.media.greaterThan(theme.breakpoints.small)(css`
    width: 442px;
    height: 352px;
  `)}

  ${theme.media.greaterThan(theme.breakpoints.medium)(css`
    width: 376px;
    height: 308px;
  `)}

  ${theme.media.greaterThan(theme.breakpoints.large)(css`
    width: 519px;
    height: 402px;
  `)}

  ${theme.media.greaterThan(theme.breakpoints.extraLarge)(css`
    width: 623px;
    height: 472px;
  `)}
`;

const stationeryCellStyles = css`
  ${cellStyles};
  width: 167px;
  height: 280px;

  ${theme.media.greaterThan(theme.breakpoints.small)(css`
    width: 205px;
    height: 347px;
  `)}

  ${theme.media.greaterThan(theme.breakpoints.medium)(css`
    width: 182px;
    height: 308px;
  `)}

  ${theme.media.greaterThan(theme.breakpoints.large)(css`
    width: 250px;
    height: 402px;
  `)}

  ${theme.media.greaterThan(theme.breakpoints.extraLarge)(css`
    width: 300px;
    height: 472px;
  `)}
`;

const onlineInvitationCellStyles = css`
  ${cellStyles};
  grid-column: span 2;
  width: calc(335px * 0.75);
  height: 280px;

  ${theme.media.greaterThan(theme.breakpoints.small)(css`
    width: calc(442px * 0.75);
    height: 352px;
  `)}

  ${theme.media.greaterThan(theme.breakpoints.medium)(css`
    width: calc(376px * 0.75);
    height: 308px;
  `)}

  ${theme.media.greaterThan(theme.breakpoints.large)(css`
    width: calc(519px * 0.6);
    height: 402px;
  `)}

  ${theme.media.greaterThan(theme.breakpoints.extraLarge)(css`
    width: calc(623px * 0.6);
    height: 472px;
  `)}
`;

const imageContainerStyles = css`
  align-self: center;
  flex-grow: 1;
  ${styleUtils.flexCenter};
  overflow: hidden;
`;

const onlineInvitationImageContainer = (backgroundColor) => css`
  background-color: ${backgroundColor};
  align-self: center;
  flex-grow: 1;
  ${styleUtils.flexCenter};
  overflow: hidden;
`;

const weddingImageContainerStyles = css`
  ${imageContainerStyles};
  max-width: 100%;
  max-height: 100%;
`;

const footerContainerTitleStyles = css`
  display: flex;
  flex-flow: column nowrap;
  margin-right: auto;
  max-width: 80%;
`;

const deletedFooterContainerTitleStyles = css`
  ${footerContainerTitleStyles};
  max-width: 60%;
`;

const footerContainerStyles = css`
  padding-left: ${theme.spacing.x2};
  padding-bottom: ${theme.spacing.x2};
  padding-top: ${theme.spacing.x2};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const titleHeadingStyles = css`
  ${theme.typeStyles.titleMediumStyles};
  ${theme.typeStyles.bodyBoldStyles};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const dateDefaultSubheadingStyles = css`
  ${theme.typeStyles.bodyExtraSmallStyles};
  color: ${theme.colors.gray500};
  padding-top: ${theme.spacing.x2};
`;

const dateDeletedSubheadingStyles = css`
  ${theme.typeStyles.bodyExtraSmallStyles};
  color: ${theme.colors.negative800};
  padding-top: ${theme.spacing.x2};
`;

const recoverButtonStyles = css`
  margin-right: ${theme.spacing.x2};
`;

const Subheading = ({ sectionType, site }) => {
  let subheadingStyle;
  let subheadingMessage;

  if (sectionType === "deleted") {
    // Add grace period to the deleted at date to get date when grace period ends
    const graceDays = config.get("sitesDeleteGraceDays");
    const dateGracePeriodEnds = addDays(
      new Date(`${site.deletedAt}+00:00`),
      graceDays,
    );
    const timeUntilDeleted = differenceInDays(dateGracePeriodEnds, new Date());

    subheadingStyle = dateDeletedSubheadingStyles;
    subheadingMessage = `${timeUntilDeleted} days until deleted`;
  } else if (sectionType === "archived") {
    const archivedDate = formatDate(
      new Date(`${site.editingExpiration}+00:00`),
      "MM/dd/yyyy",
    );

    subheadingStyle = dateDefaultSubheadingStyles;
    subheadingMessage = `Archived ${archivedDate}`;
  } else {
    const createdDate = formatDate(
      new Date(`${site.createdAt}+00:00`),
      "MM/dd/yyyy",
    );

    subheadingStyle = dateDefaultSubheadingStyles;
    subheadingMessage = `Created ${createdDate}`;
  }

  return (
    <div css={subheadingStyle} data-cy="siteCardSubheading">
      {subheadingMessage}
    </div>
  );
};

Subheading.propTypes = {
  sectionType: PropTypes.string.isRequired,
  site: PropTypes.object.isRequired,
};

const captionContainerStyles = css`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;

const captionStyles = css`
  ${theme.typeStyles.captionSmallStyles};
  height: ${theme.spacing.x6};
  ${theme.media.greaterThan(theme.breakpoints.large)(css`
    height: ${theme.spacing.x4};
  `)}
  align-items: center;
  background-color: ${theme.colors.gray200};
  display: flex;
  margin-bottom: ${theme.spacing.x4};
  margin-right: ${theme.spacing.x2};
  margin-top: ${theme.spacing.x3};
  padding: ${theme.spacing.x2};
`;

const relativeStyles = css`
  text-transform: none; // used to override text transform from captionStyles
  position: relative;
`;

const ExpirationCaptionAndIcon = ({ site }) => {
  const expirationDate = new Date(`${site.editingExpiration}+00:00`);
  const daysUntilEditingExpiration = differenceInDays(
    expirationDate,
    new Date(),
  );
  const expirationDateCaption = `archives in ${daysUntilEditingExpiration} days`;

  return (
    <div css={captionContainerStyles}>
      <div css={captionStyles} data-cy="archiveCounter">
        {expirationDateCaption}
      </div>

      {Boolean(site.password) && (
        <div css={captionStyles} data-cy="passwordIndicator">
          <div css={relativeStyles}>
            <Icon size="extraSmall" type="lock" />
          </div>
        </div>
      )}
    </div>
  );
};

Subheading.propTypes = {
  site: PropTypes.object.isRequired,
};

const getImageUrl = (colorwayCode, colorways) => {
  // Returns the default image for online invitation sites, which we retrieve from the
  // response from Dekura
  const colorway = colorways.find((cw) => cw.colorway === colorwayCode);

  if (colorway && colorway.required && colorway.required.artwork) {
    return (
      colorway.required.artwork.MobileLargeGraphic ||
      colorway.required.artwork.LargeGraphic
    );
  }

  return null;
};

const SiteCard = ({ sectionType, setErrorMessage, site }) => {
  const baseUrl = getBaseUrl(site.id);

  const [backgroundColor, setBackgroundColor] = useState(null);

  const [defaultImage, setDefaultImage] = useState(null);

  useEffect(() => {
    if (site.isOnlineInvitation) {
      const dekuraUrl = config.get("dekuraUrl");

      axios(
        urljoin(
          dekuraUrl,
          getThemeByProductCodePathFromSiteType(site.siteType),
          site.mintedProductSku,
          "/",
        ),
        {
          timeout: 5000,
        },
      )
        .then((response) => {
          const data = response.data.data;
          const design = response.data.design;
          const selectedColorwayCode = design.colorways.find(
            (colorway) =>
              colorway.external_id === site.mintedProductColorChoiceId,
          ).code;

          const selectedColorwayData = data.colorways.find(
            (colorway) => colorway.colorway === selectedColorwayCode,
          );
          const selectedColorwayBackgroundColor =
            selectedColorwayData.required.colors.backgroundColor;
          const imageUrl = getImageUrl(selectedColorwayCode, data.colorways);

          if (sectionType === "deleted") {
            setBackgroundColor(theme.colors.gray100);
          } else {
            setBackgroundColor(selectedColorwayBackgroundColor || null);
          }

          setDefaultImage(imageUrl);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [
    site.isOnlineInvitation,
    site.mintedProductColorChoiceId,
    site.mintedProductSku,
    site.siteType,
    sectionType,
    site,
  ]);

  const urls = {
    edit: site.isOnlineInvitation ? `${baseUrl}/sections` : baseUrl,
    settings: `${baseUrl}/settings`,
    view: sectionType === "archived" ? baseUrl : `//${site.domain}`,
  };

  let imageLink;

  if (sectionType === "active" || sectionType === "archived") {
    imageLink = urls.edit;
  }

  const onDeleteSite = useCallback(() => {
    API.delete(`sites/${site.id}`, {})
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        setErrorMessage(
          "An error occurred while deleting your Site. Please try again.",
        );
      });
  }, [site.id, setErrorMessage]);

  const undeleteSite = useCallback(() => {
    updateSite({
      id: site.id,
      siteData: {
        status: "published",
      },
    }).then(() => {
      window.location.reload();
    });
  }, [site.id]);
  let title = "";

  if (site.isOnlineInvitation) {
    title = site.eventHeading;
  } else if (site.isHoliday) {
    title = site.welcomeHeading;
  } else {
    title = `${site.productData.kindDescription || "Wedding Website"}${site.productData.designName ? ` - ${site.productData.designName}` : ""}`;
  }

  return (
    <div
      css={
        site.isWedding
          ? weddingCellStyles
          : site.isOnlineInvitation
            ? onlineInvitationCellStyles
            : stationeryCellStyles
      }
      data-cy={`${site.siteType}WebsiteSection`}
    >
      <div
        css={
          site.isWedding
            ? weddingImageContainerStyles
            : site.isOnlineInvitation
              ? onlineInvitationImageContainer(backgroundColor)
              : imageContainerStyles
        }
      >
        <SiteCardImage
          defaultImage={defaultImage}
          sectionType={sectionType}
          site={site}
          siteUrl={imageLink}
        />
      </div>
      <div css={footerContainerStyles}>
        <div
          css={
            sectionType === "deleted"
              ? deletedFooterContainerTitleStyles
              : footerContainerTitleStyles
          }
        >
          <div css={titleHeadingStyles} data-cy="siteName">
            {title}
          </div>

          <Subheading sectionType={sectionType} site={site} />
          {site.isHoliday && sectionType === "active" && (
            <ExpirationCaptionAndIcon site={site} />
          )}
        </div>
        {sectionType === "deleted" ? (
          <div css={recoverButtonStyles} data-cy="recoverButton">
            <TextLink
              onClick={undeleteSite}
              text="Recover"
              type={TextLink.textLinkTypes.primary}
            />
          </div>
        ) : (
          <SiteCardDropdown
            domainIsCustom={site.domainIsCustom}
            onDeleteSite={onDeleteSite}
            sectionType={sectionType}
            siteType={site.siteType}
            urls={urls}
          />
        )}
      </div>
    </div>
  );
};

SiteCard.propTypes = {
  sectionType: PropTypes.string.isRequired,
  setErrorMessage: PropTypes.func,
  site: PropTypes.object.isRequired,
};

export default SiteCard;
